<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="4">
					调岗员工(共<b>{{ totalItems }}</b
					>人)
				</a-col>
				<a-col>
					<a-row :gutter="8" type="flex" justify="end">
						<a-col :span="4">
							<a-tree-select
								@change="companyChange"
								:placeholder="l('Company')"
								style="width: 100%"
								allow-clear
								tree-default-expand-all
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								v-model="CompanyId"
							/>
						</a-col>
						<a-col :span="4">
							<a-tree-select
								v-model="DepartmentId"
								style="width: 100%"
								show-search
								:filterTreeNode="searchTree"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="DepTreeData"
								:placeholder="l('Department')"
								allow-clear
								@change="SelectDepartmentTree"
							>
							</a-tree-select>
						</a-col>
						<a-col :span="7">
							<a-range-picker :allowClear="false" @change="onDateChange" v-model="DateTime" />
						</a-col>
						<a-col :span="6" style="width: 200px;">
							<a-form-item>
								<a-input-search
									style="width: 100%"
									name="filterText"
									:placeholder="l('SearchWithThreeDot')"
									@search="search()"
									enterButton
									v-model="filterText"
								/>
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				@change="sorterChange"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<span slot="userimage" slot-scope="text, record">
					<a-avatar icon="user" :src="record.image" />
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../../shared/component-base';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	UserChangeLogServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';
import { abpService } from '../../../../shared/abp';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'transferlog',
	mixins: [AppComponentBase],
	data() {
		return {
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'userName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'userName' },
				},
				{
					title: this.l('HireDate'),
					dataIndex: 'entryDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'entryDate' },
				},
				{
					title: this.l('TransferDate'),
					dataIndex: 'transferDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'transferDate' },
				},
				{
					title: this.l('OldDepName'),
					dataIndex: 'oldDepName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'oldDepName' },
				},
				{
					title: this.l('OldPostName'),
					dataIndex: 'oldPostName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'oldPostName' },
				},
				{
					title: this.l('NewDepName'),
					dataIndex: 'newDepName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'newDepName' },
				},
				{
					title: this.l('NewPostName'),
					dataIndex: 'newPostName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'newPostName' },
				},
			],
			tableData: [],
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			DateTime: [],
			StartTime: undefined,
			EndTime: undefined,
		};
	},
	created() {
		this._userChangeLogServiceProxy = new UserChangeLogServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.StartTime = moment();
		this.EndTime = moment().add(2, 'months');
		this.DateTime = [this.StartTime, this.EndTime];
		this.getData();
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.companyChange(this.CompanyId);
		}
	},
	watch: {
		CompanyId: function(value) {
			this.getData();
		},
	},

	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},

		getData() {
			this.loading = true;
			this._userChangeLogServiceProxy
				.getTransferlogList(
					this.DepartmentId,
					this.CompanyId,
					this.StartTime,
					this.EndTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.entryDate = item.entryDate ? moment(item.entryDate).format('YYYY-MM-DD') : ' ';
						item.transferDate = item.transferDate ? moment(item.transferDate).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},
		onDateChange(e) {
			if (e.length > 0) {
				var st = e[0];
				var et = e[1];
				var newst = moment(st).format('YYYY-MM-DD 00:00:00');
				var newet = moment(et).format('YYYY-MM-DD 23:59:59');
				this.StartTime = new Date(newst);
				this.EndTime = new Date(newet);
				//this.EndTime=moment(this.StartTime).add({seconds:59,minutes:59,hours:23});
				this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
			}

			this.pageNumber = 1;
			this.request.skipCount = 0;

			this.getData();
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.getData();
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.annloading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.companyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			if (this.CompanyId) {
				//this.getData();
				this.getDepTreeData();
				this.getData();
			} else {
				this.DepartmentId = undefined;
				this.DepTreeData = [];
			}
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepartmentId = undefined;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.annloading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.DepartmentId = e;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
	},
};
</script>

<style scoped></style>
