<template>
	<a-card>
		<a-tabs default-active-key="1" @change="tabChange">
			<!--                    入职记录-->
			<a-tab-pane key="1" :tab="l('EntryLog')" forceRender>
				<internship></internship>
			</a-tab-pane>
			<!--                    转正记录-->
			<a-tab-pane key="2" :tab="l('CorrectionLog')" forceRender>
				<correctionlog></correctionlog>
			</a-tab-pane>
			<!--调岗记录-->
			<a-tab-pane key="3" :tab="l('TransferLog')" forceRender>
				<transferlog></transferlog>
			</a-tab-pane>
			<!--离职记录-->
			<a-tab-pane key="4" :tab="l('ResignLog')" forceRender>
				<resignlog></resignlog>
			</a-tab-pane>
		</a-tabs>
	</a-card>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import transferlog from '../userchangelog/transferlog/transferlog.vue';
import correctionlog from '../userchangelog/correctionlog/correctionlog.vue';
import Internship from './entrylog/entrylog';
import resignlog from '../userchangelog/resignlog/resignlog.vue';
import bus from '@/shared/bus/bus';

export default {
	name: 'userchangelog',
	mixins: [AppComponentBase],
	components: { transferlog, correctionlog, Internship, resignlog },
	methods: {
		tabChange() {
			//触发表格高度计算
			bus.$emit('calcHeight');
		},
	},
};
</script>

<style scoped></style>
